import React from "react";
import Layout from "../components/Layout/Layout";
import { NavLink } from "react-router-dom";
function Privacy() {
  return (
    <Layout title="">
      {/* <!-- Breadcumbs start --> */}
      <div class="e-breadcumb-wrap text-center">
        <h2 class="e-breadcumb-title">حریم خصوصی</h2>
        <ul class="e-breadcumb-kist">
          <li>
            <NavLink to="/">خانه</NavLink>
          </li>
          <li>
            <NavLink to="/privacy">حریم خصوصی </NavLink>
          </li>
        </ul>
      </div>
      {/* <!-- Privacy Policy start --> */}
      <div class="e-privacy-wrap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="e-privacy-sec">
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">ما کی هستیم</h2>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">نظرات</h2>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد .{" "}
                  </p>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">رسانه</h2>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد{" "}
                  </p>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">کوکیها </h2>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">
                    محتوای جاسازی شده از وب سایت های دیگر
                  </h2>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد .{" "}
                  </p>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">
                    ما اطلاعات شما را با چه کسی به اشتراک می گذاریم
                  </h2>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">
                    چه مدت داده های شما را حفظ می کنیم
                  </h2>
                  <p>
                    این ریشه در یک لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                    از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و
                    متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و
                    برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف
                    بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه
                    درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می
                    طلبد{" "}
                  </p>
                  <p>
                    Comes from لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                    صنعت چاپ و با استفاده از طراحان گرافیک است. .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">
                    چه حقوقی بر داده های خود دارید
                  </h2>
                  <p>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد .
                  </p>
                </div>
                <div class="e-privacy-box mb-30">
                  <h2 class="cmn-brdr-ttle big-ttl mb-10">
                    جایی که ما اطلاعات شما را ارسال می کنیم
                  </h2>
                  <p>
                    ریشه در یک لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                    صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون
                    بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای
                    شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود
                    ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد
                    گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Privacy;
