import axios from "axios";
import React, { useEffect, useState } from "react";
import "../assets/css/nazarsanji.css";

const Nazarsanji = ({ match }) => {
  const [currentData, setCurrentData] = useState({});
  const [isActive, setIsActive] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [userName, setUserName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const [phoneError, setPhoneError] = useState(null);

  console.log(currentQuestion);

  const paramId = match.params.id;

  //fetching the survey and its question and answers
  useEffect(() => {
    async function fetchCurrentData() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}Nazarsanji.aspx?nazarsanjiid=${paramId}`
        );


        if (data) {
          const nonEmptyJavabProperties = Object.keys(data[0])
            .filter((key) => key.startsWith("javab") && data[0][key] !== "")
            .map((key) => data[0][key]);

          setAnswers(nonEmptyJavabProperties);
          setCurrentData(data[0]);
          setIsActive(data[0].isActive);

          if(data[0].soal.length > 280){
            setCurrentQuestion(data[0].soal.slice(0 , 280) + "...")
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    fetchCurrentData();
  }, [paramId, isActive]);

  //handling input change
  const handleInputChange = (e) => {
    console.log(e.target);
    if (e.target.id === "phoneNum") {
      console.log(e.target.id);
      if (e.target.value.length === 11) {
        setPhoneNumber(e.target.value);
        setPhoneError(null);
      } else {
        setPhoneError("شماره موبایل معتبر وارد کنید");
      }
    }

    if (e.target.id === "name") {
      setUserName(e.target.value);
    }
  };

  //fetching api and sending the survey result to the server
  const hanldeSubmitForm = (e) => {
    e.preventDefault();

    if (selectedAnswer && userName && phoneNumber) {
      try {
        sendDataToServer(currentQuestion , selectedAnswer, userName , phoneNumber , paramId );
      } catch (error) {
        window.alert("اتصال برقرار نشد")
      }
    }else{
      window.alert(" گزینه مورد نظر را انتخاب کنید و اطلاعات خود را وارد نمایید")
    }
  };

    const sendDataToServer = async(
    question,
    answer,
    name,
    phoneNumber,
    surveyId
  ) => {
    const {data} = await axios.get(
      `${process.env.REACT_APP_BASE_URL}SetNazar.aspx?soal=${question}&javab=${answer}&name=${name}&phone=${phoneNumber}&nazarsanjiid=${surveyId}`
    );

    if(data === "1")
      window.alert("با موفقیت ثبت شد")
    
    if(data === "-1")
      window.alert("اتصال برقرار نشد")
  }

  return (
    <div className="survey-container container mt-4 p-5">
      {currentData ? (
        isActive ? (
          <div className="survey w-100 h-100 d-flex flex-column align-items-center">
            <h2>نظرسنجی شرکت رادین صنعت رایان</h2>
            <div className="answer-question d-flex flex-column">
              <p className="question">
                <span>سوال :</span> {currentData.soal}
              </p>

              <div className="answer-container">
                <span> جواب ها :</span>
                {answers?.map((item, index) => (
                  <div
                    onClick={() => setSelectedAnswer(item)}
                    className={
                      selectedAnswer === item
                        ? "answer mt-3 active"
                        : "answer mt-3"
                    }
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>

            <form onSubmit={hanldeSubmitForm}>
              <div class="input-group">
                <label htmlFor="phoneNum">شماره تماس</label>
                <input
                  onChange={handleInputChange}
                  inputMode="numeric"
                  id="phoneNum"
                />
                {phoneError ? (
                  <span className="error-message">{phoneError}</span>
                ) : null}
              </div>
              <div class="input-group">
                <label htmlFor="name">نام و نام خانوادگی</label>
                <input onChange={handleInputChange} type="text" id="name" />
              </div>
              <button type="submit">ثبت</button>
            </form>
          </div>
        ) : (
          <p className="deactive-message">این نظر سنجی غیرفعال میباشد!</p>
        )
      ) : (
        <p>...در حال اتصال</p>
      )}
    </div>
  );
};

export default Nazarsanji;
